/**
 * Used in taxonomy and content type tempates to extract the correct markup
 * See docs: https://developers.google.com/search/docs/guides/search-gallery
 * @param {Gatsby pageContext object} pageContext
 */
const getSchemaMarkup = (schemaMarkupObj) => {

  const publishedSchemaMarkup = schemaMarkupObj
  if (publishedSchemaMarkup) {
    try {
      return JSON.parse(publishedSchemaMarkup)
    } catch(err) {
      console.log(err)
    }
  }else{
    return null
  }

};

export default getSchemaMarkup;
