import React, { useMemo } from "react";
import { get } from "src/utils";
import getContentConnectorFields, { getContentConnectorAnchors } from "src/components/helpers/getContentConnectorFields";
import usePrependBaseURLIntoImages from "src/components/helpers/usePrependBaseURLIntoImages";
import findSiteType from "src/components/helpers/findSiteType";
import getBenefitsData from "src/components/helpers/getBenefitsData";
import getOGMarkup from "src/components/helpers/getOGMarkup";
import getSchemaMarkup from "src/components/helpers/getSchemaMarkup";
import loadable from '@loadable/component'
import getFirstOneHavingData from "src/../helpers/getFirstOneHavingData";
import { getCommonHeroImages } from "src/utils";
import "src/scss/aboutpages/_aboutpages.scss";
import "src/scss/aboutpages/_abouttemplatepages.scss";
import { SEO, CommonHero } from "src/components/commonComponents";
import { getPageDataJsonPath } from "src/utils";
import getImageObject from "../../helpers/getImageObject";
const Statistics = loadable(() => import('src/components/commonComponents'), { resolveComponent: (components) => components.Statistics });
const Benefits = loadable(() => import('src/components/commonComponents'),{ resolveComponent: (components) => components.BenefitsWrapper});
const SideBar = loadable(() => import('src/components/resources/SideBar'));
const ConditionalWrapper = loadable(() => import("src/components/helpers/ConditionalWrapper"))
const QuestionsCTA = loadable(() => import('src/components/commonComponents'), { resolveComponent: (components) => components.QuestionsCTA })
const ContentConnector = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.ContentConnector
 });
 const PricingSection = loadable(() => import("src/components/commonComponents"), { resolveComponent: (components) => components.PricingSection });
const ScrollLinks = loadable(() => import('src/components/commonComponents'), { resolveComponent: (components) => components.SecondaryNavbar });
const CompanyLogos = loadable(() => import("src/components/solutions/CompanyLogos"))
const BannerTiles = loadable(() => import('src/components/commonComponents'), { resolveComponent: (components) => components.BannerTiles });

const sectionStyle = "tw-pt-0 md:tw-pb-0 !tw-p-[0_15px]";

const AboutUsPagesTemplate = ({ pageContext, path }) => {
  const locale = pageContext.locale || "en";
  // const crumbs = [{ title: "Home", link: locale === "en" ? "/" : "/uk/" }, { title: pageContext.title }];

  // const Working_Station =
  //   "/accruent_careers_hero_working-station-illustration.svg";

  const heroImage = pageContext?.commonHeroProps?.heroImage
  const heroImageGif = get(
    pageContext,
    "fieldHeroGifImage"
  );
  let contentConnectors = [];
  if (Array.isArray(pageContext?.relationships?.fieldContentSections)) {
    contentConnectors = getContentConnectorFields(
      pageContext.relationships.fieldContentSections,
      "aboutUsPage",
      locale
    );
  }

  const logoContent = pageContext?.relationships?.fieldBrandLogosSection;
  const isSideBar = pageContext?.fieldShowRecentResourcesOnS;
  const isBannerTiles = !!pageContext?.relationships?.fieldBannerTiles;
  const anchors = getContentConnectorAnchors(contentConnectors);
  usePrependBaseURLIntoImages(["basic-page-body"]);
  const isMc = findSiteType('mc');
  const isEms = findSiteType('ems');
  let benefitsData;
  if (!!pageContext?.relationships?.fieldAboutBenefits?.length && pageContext?.relationships?.fieldAboutBenefits[0]) {
    benefitsData = getBenefitsData(pageContext?.relationships?.fieldAboutBenefits[0], pageContext?.type?.id, false, locale)
  }
  const schemaMarkup = getSchemaMarkup(pageContext?.fieldSchemaMarkup)
  const schemaMarkupVideo = getSchemaMarkup(pageContext?.fieldVideoSchemaMarkup)

  const statisticsSection = getFirstOneHavingData(pageContext?.relationships?.fieldParagraphStatisticsSect, pageContext?.relationships?.fieldStatistic?.[0])
  const isCenterAllignStatistics = (statisticsSection?.fieldStatisticSectionType === 'percentage_value_content_centered_align')
  const OGMarkup = getOGMarkup(pageContext.title, 'about us', { gatsbyImage: heroImage?.shareImage });
  const { entityMetatags: metaTags } = pageContext;

  let hreflangAlternatives = []

  pageContext.hreflangData?.forEach(hrefData => {
    hreflangAlternatives.push({
      href: hrefData.path,
      hreflang: hrefData.locale
    })
  });

  hreflangAlternatives?.find(trans => {
    if (trans.hreflang == "en") {
      hreflangAlternatives.push({
        href: trans.href,
        hreflang: "x-default"
      })
    } else if (hreflangAlternatives.length < 2) {
      if (trans.hreflang !== "en") {
        hreflangAlternatives.push({
          href: trans.href,
          hreflang: "x-default"
        })
      }
    }
  })
  const pageDataPath = getPageDataJsonPath(pageContext?.entityUrl?.path)
  const sectionContainerClass = "tw-mb-[52px] md:tw-mb-[72px] tw-pt-0 tw-pb-0"
  const preloadImages = useMemo(() => getCommonHeroImages(pageContext?.commonHeroProps), [pageContext?.commonHeroProps])
  const logos = getImageObject(logoContent?.relationships?.fieldLogoImages, logoContent?.fieldLogoImages, "fieldLogoImages")
  return (
    <div className={`tw-new-about-page tw-new-about-page ${isMc ? "new-about-page-mc tw-new-about-page-mc" : isEms ? "new-about-page-ems tw-new-about-page-ems" : ""}`}>
      <SEO
        lang={locale}
        alternatives={hreflangAlternatives}
        title={pageContext.title}
        pageUrl={pageContext?.entityUrl?.path}
        meta={metaTags}
        OGMarkup={OGMarkup}
        preloadedImages={preloadImages}
        schemaMarkup={schemaMarkup}
        schemaMarkupVideo={schemaMarkupVideo}
        preloads={[pageDataPath]}
      />
      <CommonHero
        commonHeroProps={pageContext?.commonHeroProps}
        contentProp={"AboutUs"}
      />
      {isBannerTiles && <div className="container banner-tiles md:tw-relative md:tw-top-[-34px] !tw-mb-[-34px]">
        <BannerTiles data={pageContext?.relationships?.fieldBannerTiles} />
      </div>
      }
      <div className="container">
        {!!benefitsData && (
          <div className={sectionContainerClass}>
            <section className="section">
              <Benefits benefits={[benefitsData]} locale={locale} />
            </section>
          </div>
        )
        }
        {statisticsSection?.status &&
          (<div className={"section columns " + (isCenterAllignStatistics ? 'styled-static-container tw-pl-[1rem] tw-pr-[1rem] md:tw-pl-0 md:tw-pr-0 md:tw-mb-[72px] ' : 'static-container')}>
            <Statistics
              entity={statisticsSection}
              statistics={
                statisticsSection?.relationships
                  ?.fieldStatistic
              }
              sectionStyle={isCenterAllignStatistics ? '' : 'statistics_left_content_right'}
              spread
            />
          </div>)
        }
        {!!pageContext?.relationships?.fieldContentCard?.length &&
          (<div className="section about-section tw-pt-[8px]">
            <div className="columns about-description-container tw-mt-[2px]">
              <div className="column is-8 is-offset-2">
                <h2 className="title has-text-centered title-3 mb-1 tw-mb-0">
                  {pageContext.relationships.fieldContentCard[0]?.title}
                </h2>
                <div
                  className="block is-size-5-desktop has-text-centered content !tw-text-[21px] tw-leading-[28px] tw-text-accruent_black dangerously-html-handle"
                  dangerouslySetInnerHTML={{
                    __html:
                      pageContext.relationships.fieldContentCard[0]?.body
                        .value,
                  }}
                />
              </div>
            </div>
          </div>)}
        {logoContent &&
          <div className="section logos-section logo-content">
            <CompanyLogos
              data={logos}
              fieldTitle={
                !logoContent.fieldHideTitle ? logoContent.title : null
              }
              showBackgroundPattern={logoContent.fieldShowBackgroundPattern}
              {...logoContent}
            />
          </div>
        }
        {pageContext?.relationships?.fieldPricingSection &&
          <PricingSection locale={locale} className={sectionContainerClass} fieldPricingSection={pageContext?.relationships?.fieldPricingSection} />
        }
      </div>
      {!!anchors.length &&
        <ScrollLinks
          title={pageContext.fieldTermJumpLinksHeader}
          anchors={anchors}
          offset={-185}
        />
      }
      <div className={`container content-visibility-auto-mobile ${!!anchors.length ? "add-margin-top" : ""}`}>
        <ConditionalWrapper
          condition={isSideBar}
          wrapper={(children) => (
            <div className="columns">
              <div className="column is-9">
                {children}
              </div>
              <div className="column is-3 sidebar-column tw-pl-[25px] tw-pr-[25px] tw-mb-[20px] md:tw-pr-[35px] md:tw-pl-0 md:tw-mb-0">
                <SideBar locale={locale} />
              </div>
            </div>
          )}
        >
          <>
            {contentConnectors.map((data, index) => (
              <div key={index} className={sectionContainerClass}>
                <ContentConnector
                  key={index}
                  data={data}
                  pageType={pageContext.type?.id}
                  locale={locale}
                />
              </div>
            ))}
          </>
        </ConditionalWrapper>
      </div>

      {pageContext?.fieldShowQuestionSection &&
        <section className="section  questions-wrapper tw-mb-[76px]">
          <div className="container">
            <div className="columns">
              <div className="column is-6-desktop">
                <h2 className="title title-3">Got Questions?</h2>
                <p className="is-size-5">
                  Contact one of our departments to solve your problem.
                </p>
              </div>
            </div>
            <QuestionsCTA />
          </div>
        </section>}
    </div>
  );
};

export default AboutUsPagesTemplate;